import Dexie from "dexie";
import axios from "axios";

// Initialize Dexie
export const db = new Dexie("chatApp");
db.version(1).stores({
  chat: `++id, message, author, timestamp`,
  users: `++id, username, email, password, avatar`,
});

// Default avatar URL
// const DEFAULT_AVATAR =
//   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlBGl8T5ptpVOfkoma4hp6sO_4yu-G2iiopzutTobD0-s_XXf9Lf9fqmnoA02dADZFSLI&usqp=CAU";

// Backend API URL
const API_URL = "https://api.dein.work/dev";

export const checkIfUserExists = async (phoneNumber) => {
  try {
    const response = await axios.post(`${API_URL}/auth/check/phone`, {
      phone: phoneNumber, // Send phone number in the request body
    });
    if (response.data.data.status === 1) {
      return true; 
    }
    else if (response.data.data.status === 0) {
      return false;
    }
    else if (response.data.message === "无效的手机号码") {
      throw new Error("无效的手机号码");
    }
    // Handle any other unexpected responses
    else {
      throw new Error(response.data.message || "Unexpected error occurred");
    }
  } catch (error) {
    // Catch and re-throw any errors from the server or connection issues
    throw new Error(error.response?.data?.message || "检查用户失败");
  }
};

// Function to send phone number verification code
export const sendVerificationCode = async (phoneNumber, type) => {
  try {
    // Send POST request to the API to send the verification code
    const response = await axios.post(`${API_URL}/captcha/sms`, {
      phone: phoneNumber,
      type,
    });

    // Handle the response from the API
    if (response.data.success) {
      return { success: true, message: "验证码已发送" };
    } else {
      return {
        success: false,
        message: response.data.message || "发送验证码失败",
      };
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || "发送验证码失败");
  }
};

// Function to sign up a new user using phone verification only
export const signupUser = async (phoneNumber, verificationCode) => {
  try {

    const response = await axios.post(`${API_URL}/auth/register/sms`, {
      phone: phoneNumber,
      code: verificationCode,
    });

    if (response.data.message === "OK" && response.data.data.token) {
      const token = response.data.data.token;
      sessionStorage.setItem("token", token);

      return {
        success: true,
        message: "通过手机验证码注册成功",
        username: token,
      };
    } else {
      throw new Error(response.data.message || "手机验证码注册失败");
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || "注册失败");
  }
};


// Function to validate login credentials
export const loginUser = async ({
  phoneNumber,
  verificationCode,
  username,
  password,
}) => {
  try {
    if (phoneNumber && verificationCode) {
      const response = await axios.post(`${API_URL}/auth/login/sms`, {
        phone: phoneNumber,
        code: verificationCode,
      });
      if (response.data.message === "OK" && response.data.data.token) {
        const token = response.data.data.token;
        sessionStorage.setItem("token", token);
        return {
          success: true,
          message: "通过手机验证登录成功",
          username: token,
        };
      } else {
        throw new Error(response.data.message || "手机验证码登录失败");
      }
    }

    if (username && password) {
      const response = await axios.post(`${API_URL}/auth/login/password`, {
        username: username,
        password: password,
      });

      if (response.data.message === "OK" && response.data.data.token) {
        const token = response.data.data.token;
        sessionStorage.setItem("token", token);
        return {
          success: true,
          message: "用户名和密码登录成功",
          username: token,
        };
      } else {
        throw new Error(response.data.message || "用户名或密码错误");
      }
    }

    // If neither phoneNumber nor username/password is provided, throw an error
    throw new Error("登录需要手机号验证码或用户名和密码。");
  } catch (error) {
    throw new Error(error.response?.data?.message || "登录失败");
  }
};

// Function to update the user's avatar
export const updateAvatar = async (username, newAvatarUrl) => {
  // Find the user by username and update the avatar
  const user = await db.users.where({ username }).first();
  if (user) {
    await db.users.update(user.id, { avatar: newAvatarUrl });
    // sessionStorage.setItem("avatar", newAvatarUrl); // Update session storage
    return { success: true, message: "Avatar updated successfully" };
  } else {
    throw new Error("User not found");
  }
};

// // Function to update the user's avatar using Axios
// export const updateAvatar = async (username, newAvatarUrl) => {
//   try {
//     const response = await axios.put(`${API_URL}/users/${username}/avatar`, {
//       avatar: newAvatarUrl,
//     });

//     // Update session storage
//     sessionStorage.setItem("avatar", newAvatarUrl);

//     return response.data; // Return the response from the backend
//   } catch (error) {
//     throw new Error(error.response?.data?.message || "Avatar update failed");
//   }
// };

// Dexie: Chat-related functions

// Function to add a message with timestamp
export const addMessage = async (message, author, recipient) => {
  const timestamp = new Date().toISOString();

  // Fetch the last message to calculate the next id
  const lastMessage = await db.chat.orderBy("id").last();
  const nextId = lastMessage ? lastMessage.id + 1 : 1;

  // Add the new message to the database
  await db.chat.add({ id: nextId, message, author, recipient, timestamp });
};

// Function to get all messages
export const getMessages = async () => {
  return await db.chat.orderBy("id").toArray();
};

// Function to delete a message by id and reindex the remaining messages
export const deleteMessage = async (id) => {
  // Delete the message with the given id
  await db.chat.delete(id);

  // Get all remaining messages and reindex them
  const messages = await db.chat.orderBy("id").toArray();

  // Start a transaction to update all message ids to ensure they are sequential
  await db.transaction("rw", db.chat, async () => {
    for (let i = 0; i < messages.length; i++) {
      // Update each message to have the correct id (i + 1)
      await db.chat.update(messages[i].id, { id: i + 1 });
    }
  });
};

// Function to clear all messages from the database
export const clearMessages = async () => {
  await db.chat.clear();
};
