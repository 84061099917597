import "./widget.css";
import React, { useState } from "react";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";

const Widget = () => {
  // State to track whether the heart is clicked
  const [isLiked, setIsLiked] = useState(false);

  // State for the like counter
  const [likeCount, setLikeCount] = useState(122);

  // Handle click on heart icon
  const handleLikeClick = () => {
    if (!isLiked) {
      setLikeCount(likeCount + 1); // Increment like count when liked
    } else {
      setLikeCount(likeCount - 1); // Decrement like count when unliked
    }
    setIsLiked(!isLiked); // Toggle like state
  };

  return (
    <div className="widget">
      <div className="widget-card">
        <div className="image-container">
          <img
            src="https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w1200/2023/10/free-images.jpg"
            alt="free-image"
          />
          <div className="overlay">
            <div className="profile-container">
              <div className="user-info">
                <img
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="user"
                  className="user-avatar"
                />
                <span className="username">李湘洁</span>
              </div>

              <div className="tag-container">
                <span className="user-tag">重金属</span>
                <span className="user-tag">摇滚</span>
              </div>
            </div>

            <div className="like-section" onClick={handleLikeClick}>
              {isLiked ? (
                <FavoriteIcon className="heart-icon liked" />
              ) : (
                <FavoriteBorderOutlinedIcon className="heart-icon" />
              )}
              <span className="like-counter">{likeCount}</span>
            </div>
          </div>
        </div>
        <div className="content">
          <p className="description">
            整体不错，刚开始，吉他的混响+延时都有点过大，高潮时那个鼓的轮子换个刺激点的，再一个，高潮过程中，来点失真吉他简单的长音符作为飘逸感来烘托主旋律的叠加色彩
          </p>
          <div className="user-details">
            <img
              src="https://images.unsplash.com/photo-1640960543409-dbe56ccc30e2?q=80&w=2725&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="user"
              className="user-avatar-small"
            />
            <span className="username-small">菠萝</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widget;
