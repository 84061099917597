import React, { useState, useEffect, useContext } from "react";
import "./chatsidebar.scss";
import { db } from "../../../../db";
import Chats from "./Chats";
import ChatNavbar from "../chat-navbar/ChatNavbar";
import UserList from "./UserList";
import { ChatContext } from "../../../../contexts/ChatContext";
import { AuthContext } from "../../../../contexts/AuthContext"; // Import AuthContext to get current user

const ChatSidebar = () => {
  const { forceUpdate, selectedUser, setSelectedUser } =
    useContext(ChatContext);
  const { currentUser } = useContext(AuthContext); 
  const [recentMessages, setRecentMessages] = useState([]);
  const [showUserList, setShowUserList] = useState(false);

  useEffect(() => {
    const fetchRecentMessages = async () => {
      if (!currentUser) return; 

      const messages = await db.chat.orderBy("timestamp").toArray();
      const userMessages = {};

      messages.forEach((message) => {
        if (
          message.recipient === currentUser ||
          message.author === currentUser
        ) {
          const participants = [message.author, message.recipient].sort();
          const key = `${participants[0]}-${participants[1]}`;
          userMessages[key] = message;
        }
      });

      // console.log("UserMessages Object:", userMessages);
      setRecentMessages(Object.values(userMessages)); 
    };

    fetchRecentMessages();
  }, [forceUpdate, currentUser]);

  const handleUserSelectFromSearch = (author) => {
    setSelectedUser(author);
    setShowUserList(false);
  };

  const handleSelectNewUser = (username) => {
    setSelectedUser(username);
    setShowUserList(false);
  };

  return (
    <div className="chat-sidebar">
      <ChatNavbar onSelectUser={handleUserSelectFromSearch} />
      <button onClick={() => setShowUserList(!showUserList)}>
        {showUserList ? "Hide Users" : "Show Users"}
      </button>
      {showUserList && <UserList onSelectUser={handleSelectNewUser} />}
      <Chats
        currentUser={currentUser}
        recentMessages={recentMessages}
        selectedUser={selectedUser}
        onSelectUser={setSelectedUser}
      />
    </div>
  );
};

export default ChatSidebar;
