import React, { useState, useContext } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { db } from "../../../../db";
import "./chat-navbar.scss";
import { AuthContext } from "../../../../contexts/AuthContext";

const ChatSearch = ({ onSelectUser }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [userAvatars, setUserAvatars] = useState({}); 

  // Function to handle search input change
  const handleSearch = async (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);

    if (searchValue.trim()) {
      // Query the database for messages that match the search term
      const messages = await db.chat
        .where("message")
        .startsWithIgnoreCase(searchValue)
        .toArray();

      // Filter messages to show only those where the author or recipient is the current user
      const filteredMessages = messages.filter(
        (msg) => msg.author === currentUser || msg.recipient === currentUser
      );

      // Fetch avatars for authors and recipients
      const userList = filteredMessages.reduce((users, msg) => {
        if (!users.includes(msg.author)) users.push(msg.author);
        if (!users.includes(msg.recipient)) users.push(msg.recipient);
        return users;
      }, []);

      const userAvatarsMap = await fetchUserAvatars(userList);
      setUserAvatars(userAvatarsMap);

      setSearchResults(filteredMessages);
    } else {
      setSearchResults([]);
    }
  };

  // Function to fetch avatars for a list of users
  const fetchUserAvatars = async (usernames) => {
    const avatars = {};
    const userRecords = await db.users.where("username").anyOf(usernames).toArray();
    userRecords.forEach((user) => {
      avatars[user.username] = user.avatar;
    });
    return avatars;
  };

  // Function to navigate to a chat with a specific user
  const handleSelectMessage = (author) => {
    if (onSelectUser) {
      onSelectUser(author);
    }
    setSearchTerm("");
    setSearchResults([]); 
  };

  return (
    <div className="chat-search">
      <div className="search-form">
        <SearchOutlinedIcon className="search-icon" />
        <input
          type="text"
          placeholder="搜索消息"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      {searchResults.length > 0 && (
        <ul className="search-results">
          {searchResults.map((result) => (
            <li
              key={result.id}
              className="search-result-item"
              onClick={() => handleSelectMessage(result.author)}
            >
              <div className="result-avatar">
                <img
                  src={userAvatars[result.author]} 
                  alt="avatar"
                />
              </div>
              <div className="result-content">
                <span className="author">{result.author}</span>
                <span className="message-preview">{result.message}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ChatSearch;
