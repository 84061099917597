import "./sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext"; // Import AuthContext
import LibraryMusicOutlinedIcon from "@mui/icons-material/LibraryMusicOutlined";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import LoginPage from "../../pages/login/LoginPage"; 

const Sidebar = () => {
  const location = useLocation(); // Get the current path
  const [selectedItem, setSelectedItem] = useState(location.pathname);
  const { currentUser } = useContext(AuthContext); // Get the current user from AuthContext
  const [showLoginPage, setShowLoginPage] = useState(false); // State to manage login modal visibility

  const handleSelection = (event, path) => {
    // Prevent the default link behavior if the user is not logged in
    if (!currentUser && path !== "/") {
      event.preventDefault(); // Prevent link from navigating
      setShowLoginPage(true); // Show the login page
    } else {
      setSelectedItem(path); // Update selected item
    }
  };

  const closeLogin = () => {
    setShowLoginPage(false); // Close the login page
  };

  const handleButtonClick = () => {
    console.log(currentUser)
    if (!currentUser) {
      setShowLoginPage(true); // Show the login page if the user is not logged in
    } else {
      // Navigate to the demo publishing page or perform a demo submission action
      console.log("Navigating to demo submission or publishing...");
    }
  };

  return (
    <div className="sidebar">
      <div className="top">
        <img
          src={process.env.PUBLIC_URL + "/DEIN.png"}
          alt="DEIN Logo"
          className="logo"
        />
      </div>
      <div className="center">
        <ul>
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={(e) => handleSelection(e, "/")}
          >
            <li className={selectedItem === "/" ? "selected" : ""}>
              {selectedItem === "/" ? (
                <LibraryMusicIcon />
              ) : (
                <LibraryMusicOutlinedIcon />
              )}
              <span>发现</span>
            </li>
          </Link>
          <Link
            to="/recommendation"
            style={{ textDecoration: "none" }}
            onClick={(e) => handleSelection(e, "/recommendation")}
          >
            <li
              className={selectedItem === "/recommendation" ? "selected" : ""}
            >
              {selectedItem === "/recommendation" ? (
                <TipsAndUpdatesIcon />
              ) : (
                <TipsAndUpdatesOutlinedIcon />
              )}
              <span>推荐</span>
            </li>
          </Link>
          <Link
            to="/follow"
            style={{ textDecoration: "none" }}
            onClick={(e) => handleSelection(e, "/follow")}
          >
            <li className={selectedItem === "/follow" ? "selected" : ""}>
              {selectedItem === "/follow" ? (
                <EmojiEmotionsIcon />
              ) : (
                <EmojiEmotionsOutlinedIcon />
              )}
              <span>关注</span>
            </li>
          </Link>
          <Link
            to="/community"
            style={{ textDecoration: "none" }}
            onClick={(e) => handleSelection(e, "/community")}
          >
            <li className={selectedItem === "/community" ? "selected" : ""}>
              {selectedItem === "/community" ? (
                <HomeWorkIcon />
              ) : (
                <HomeWorkOutlinedIcon />
              )}
              <span>社区</span>
            </li>
          </Link>
          <Link
            to="/messages"
            style={{ textDecoration: "none" }}
            onClick={(e) => handleSelection(e, "/messages")}
          >
            <li className={selectedItem === "/messages" ? "selected" : ""}>
              {selectedItem === "/messages" ? (
                <MarkunreadIcon />
              ) : (
                <MarkunreadOutlinedIcon />
              )}
              <span>消息</span>
            </li>
          </Link>
          <Link
            to="/notifications"
            style={{ textDecoration: "none" }}
            onClick={(e) => handleSelection(e, "/notifications")}
          >
            <li className={selectedItem === "/notifications" ? "selected" : ""}>
              {selectedItem === "/notifications" ? (
                <NotificationsIcon />
              ) : (
                <NotificationsNoneOutlinedIcon />
              )}
              <span>通知</span>
            </li>
          </Link>
          <Link
            to="/profile"
            style={{ textDecoration: "none" }}
            onClick={(e) => handleSelection(e, "/profile")}
          >
            <li className={selectedItem === "/profile" ? "selected" : ""}>
              {selectedItem === "/profile" ? (
                <AccountCircleIcon />
              ) : (
                <AccountCircleOutlinedIcon />
              )}
              <span>我的</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className="bottom">
        {/* Conditionally render the button based on whether the user is logged in */}
        <button className="demo-button" onClick={handleButtonClick}>
          {currentUser ? "发布DEMO" : "登录/注册"}
        </button>
      </div>
      {/* Conditionally render the login page */}
      {showLoginPage && <LoginPage close={closeLogin} />}
    </div>
  );
};

export default Sidebar;
