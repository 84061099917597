// Chat.jsx
import React, { useState, useEffect, useContext } from "react";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import VideocamIcon from "@mui/icons-material/Videocam";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./chat.scss";
import Messagess from "../chat-message/Messagess";
import ChatInput from "../chat-input/ChatInput";
import { getMessages } from "../../../../db";
import { ChatContext } from "../../../../contexts/ChatContext";
import { AuthContext } from "../../../../contexts/AuthContext";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const { currentUser} = useContext(AuthContext);
  const { selectedUser, forceUpdate } = useContext(ChatContext);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!currentUser || !selectedUser) return;
      const msgs = await getMessages();

      // Filter messages for the selected user
      const filteredMessages = msgs.filter(
        (msg) =>
          (msg.author === currentUser && msg.recipient === selectedUser) ||
          (msg.author === selectedUser && msg.recipient === currentUser)
      );
      setMessages(filteredMessages); // Update the state with filtered messages
    };

    if (!currentUser || !selectedUser) return; // Wait until both users are set
    fetchMessages(); // Fetch messages whenever forceUpdate or selectedUser changes
  }, [forceUpdate, currentUser, selectedUser]);

  return (
    <div className="chat">
      <div className="chat-info">
        <span>{selectedUser || "Select a user"}</span>
        <div className="chat-icons">
          <PersonAddAlt1Icon />
          <VideocamIcon />
          <MoreHorizIcon />
        </div>
      </div>
      <Messagess
        initialMessages={messages}
        selectedUser={selectedUser}
        currentUser={currentUser}
      />
      <ChatInput selectedUser={selectedUser} />
    </div>
  );
};

export default Chat;
