import "./discover.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Widget from "../../components/widget/Widget";
import { Routes, Route } from "react-router-dom";

const Discover = () => {
  return (
    <div className="discover">
      <Sidebar />
      <div className="discover-container">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <div className="widgets">
                <Widget />
                <Widget />
                <Widget />
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Discover;
