import React, { useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import "./dropdown.css";
import { AuthContext } from "../../contexts/AuthContext"; // Import AuthContext to access currentUser

const DropDownProfile = ({ setOpenProfile }) => {
  const dropdownRef = useRef(null);
  const { setCurrentUser } = useContext(AuthContext); // Access setCurrentUser from AuthContext

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenProfile(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenProfile]);

  // Function to close the dropdown when a link is clicked
  const handleLinkClick = () => {
    setOpenProfile(false); // Close the dropdown after clicking a link
  };

  // Handle user logout
  const handleLogout = () => {
    setCurrentUser(null);
    sessionStorage.clear(); 
    window.location.reload(); 
  };

  return (
    <div ref={dropdownRef} className="dropDownProfileContainer">
      <ul className="dropDownProfileMenu">
        <li>
          <Link to="/profile" onClick={handleLinkClick}>
            个人
          </Link>
        </li>
        <li onClick={handleLinkClick}>设置</li>
        <li onClick={handleLogout}>登出</li>
      </ul>
    </div>
  );
};

export default DropDownProfile;
