// Messagess.jsx
import React, { useEffect, useRef, useMemo } from "react";
import Message from "./Message";
import "./chat-message.scss";

const Messagess = ({ initialMessages, currentUser }) => {
  const chatMessagesRef = useRef(null); 

  const messages = useMemo(() => initialMessages || [], [initialMessages]);

  // Scroll to the bottom of the chat when new messages are added
  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [messages]); // Run the effect when messages change

  return (
    <div className="chat-messages" ref={chatMessagesRef}>
      {messages.map((msg) => (
        <Message
          key={msg.id}
          message={msg.message}
          author={msg.author}
          timestamp={msg.timestamp}
          currentUser={currentUser}
        />
      ))}
    </div>
  );
};

export default Messagess;
