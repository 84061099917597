import React, { useState } from "react";
import "./profile.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import ProfileHeader from "../../components/profileHeader/ProfileHeader";
import DemoCard from "../../components/democard/democard";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("demo"); // State to manage active tab

  return (
    <div className="profile">
      <Sidebar />
      <div className="profile-container">
        <div className="profile-page-container">
          <Navbar />
          <ProfileHeader />
        </div>

        {/* Tab Section */}
        <div className="tab-container">
          <h2
            className={`title ${activeTab === "demo" ? "active" : ""}`}
            onClick={() => setActiveTab("demo")}
          >
            DEMO
          </h2>
          <h2
            className={`title ${activeTab === "乐评" ? "active" : ""}`}
            onClick={() => setActiveTab("乐评")}
          >
            乐评
          </h2>
          <h2
            className={`title ${activeTab === "喜欢" ? "active" : ""}`}
            onClick={() => setActiveTab("喜欢")}
          >
            喜欢
          </h2>
        </div>

        {/* Content Section */}
        <div className="demo-section">
          {activeTab === "demo" && (
            <div className="democard-grid-container">
              <DemoCard />
              <DemoCard />
              <DemoCard />
              <DemoCard />
              <DemoCard />
              <DemoCard />
              <DemoCard />
              <DemoCard />
              <DemoCard />
              <DemoCard />
            </div>
          )}
          {activeTab === "乐评" && (
            <div className="review-section">乐评</div>
          )}
          {activeTab === "喜欢" && (
            <div className="favorites-section">喜欢</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
