import "./follow.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const Follow = () => {
  return (
    <div className="follow">
      <Sidebar />
      <div className="follow-container">
        <Navbar />
      </div>
    </div>
  );
};

export default Follow;
