import React, { createContext, useState, useEffect } from "react";

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider component to provide authentication context
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null); // Start with null

  // Retrieve the current user from session storage when the component mounts
  useEffect(() => {
    const storedUser = sessionStorage.getItem("token");
    
    if (storedUser !== undefined && storedUser !== null) {
      setCurrentUser(storedUser); // Set the user from session storage if valid
    } else {
      setCurrentUser(null); 
    }
  }, []);

  // // Log when the currentUser state is updated
  // useEffect(() => {
  //   console.log("Current user in AuthContext:", currentUser);
  // }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
