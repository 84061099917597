// Message.jsx
import React, { useState, useEffect } from "react";
import "./chat-message.scss";
import { db } from "../../../../db"; 

// Helper function to format the date
const formatDate = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);

  if (
    now.getFullYear() === date.getFullYear() &&
    now.getMonth() === date.getMonth() &&
    now.getDate() === date.getDate()
  ) {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  if (
    yesterday.getFullYear() === date.getFullYear() &&
    yesterday.getMonth() === date.getMonth() &&
    yesterday.getDate() === date.getDate()
  ) {
    return `Yesterday ${date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })}`;
  }

  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay());
  if (date >= startOfWeek) {
    return date.toLocaleDateString([], {
      weekday: "long",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  return date.toLocaleDateString('zh-CN', {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

const Message = ({ author, message, timestamp, currentUser }) => {
  const [avatar, setAvatar] = useState(null); 

  // Fetch avatar for the author
  useEffect(() => {
    const fetchAvatar = async () => {
      const user = await db.users.where({ username: author }).first();
      if (user && user.avatar) {
        setAvatar(user.avatar);
      }
    };

    fetchAvatar();
  }, [author]); 

  // Determine if the message is owned by the current user
  const isOwner = author === currentUser;

  return (
    <div className={`chat-message ${isOwner ? "owner" : ""}`}>
      <div className="message-info">
        {avatar && ( 
          <img
            src={avatar}
            alt={author}
          />
        )}
        <span>{author}</span>
      </div>
      <div className="message-content">
        <p>{message}</p>
        <span className="message-time">{formatDate(timestamp)}</span>
      </div>
    </div>
  );
};

export default Message;
