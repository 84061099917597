import React, { useState, useEffect, useRef, useContext } from "react";
import "./profileHeader.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { updateAvatar, db } from "../../db"; 

const ProfileHeader = () => {
  const [profilePhoto, setProfilePhoto] = useState(""); // Start with an empty string
  const [randomName, setRandomName] = useState(""); // State to store the random name
  const [showToken, setShowToken] = useState(false); // State to show/hide UID token
  const fileInputRef = useRef(null);
  const { currentUser } = useContext(AuthContext);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  // Function to generate a random user name in the format "DEIN用户_randomNum"
  const generateRandomName = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    return `DEIN用户_${randomNum}`;
  };

  // Assign random name to current user on component mount
  useEffect(() => {
    if (currentUser) {
      const randomUserName = generateRandomName();
      setRandomName(randomUserName);
    }
  }, [currentUser]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFollowClick = () => {
    setIsFollowing(!isFollowing);
  };

  const handleNameClick = () => {
    setShowToken(!showToken); // Toggle the display of the UID token
  };

  // Fetch avatar from database based on current user
  useEffect(() => {
    const fetchAvatar = async () => {
      if (currentUser) {
        try {
          const user = await db.users.where({ username: currentUser }).first();
          if (user && user.avatar) {
            setProfilePhoto(user.avatar);
          } else {
            setProfilePhoto("profile-photo-url");
          }
        } catch (error) {
          console.error("Failed to fetch avatar:", error.message);
        }
      }
    };
    fetchAvatar();
  }, [currentUser]);

  // Handle file change event
  const handlePhotoChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Convert file to base64 or upload to server and get the URL
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64data = reader.result;
          setProfilePhoto(base64data);

          // Update avatar in the database with base64 or server URL
          await updateAvatar(currentUser, base64data);
          console.log("Avatar updated successfully");
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Failed to update avatar:", error.message);
      }
    }
  };

  // Function to handle click on the photo
  const handlePhotoClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="profile-header-card">
      <div className="profile-header">
        <div className="profile-photo" onClick={handlePhotoClick}>
          <img src={profilePhoto} alt="Profile" />
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handlePhotoChange}
          />
        </div>
        <div className="profile-info">
          {/* Display random name and click to show token */}
          <h2 onClick={handleNameClick} style={{ cursor: "pointer" }}>
            {randomName || "用户"}
          </h2>
          {/* Conditionally show the UID token on click */}
          {showToken && <p className="uid-token">UID Token: {currentUser}</p>}
          <div className="profile-stats">
            <span>关注 10</span>
            <span>粉丝 100</span>
            <span>获赞 20</span>
          </div>
          <div className="profile-tags">
            <span>歌手</span>
            <span>作词</span>
            <span>作曲</span>
            <span>编曲</span>
          </div>
          <div
            className={`profile-description ${isExpanded ? "expanded" : ""}`}
          >
            音乐制作人/music producer/录音师/sound
            engineer/混音师/mixer/调音师/tuner/母带工...
            <button className="toggle-btn" onClick={toggleExpand}>
              {isExpanded ? "收起" : "展开更多"}
            </button>
          </div>
        </div>
      </div>
      <div className="profile-actions">
        <button className="message-btn">私信</button>
        <button
          className={`follow-btn ${isFollowing ? "following" : ""}`}
          onClick={handleFollowClick}
        >
          {isFollowing ? "已关注" : "关注"}
        </button>
      </div>
    </div>
  );
};

export default ProfileHeader;
