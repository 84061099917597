import React, { useState, useContext } from "react";
import { checkIfUserExists, sendVerificationCode, loginUser } from "../../db";
import { AuthContext } from "../../contexts/AuthContext";
import SignUpPage from "../signup/Signup"; // Import SignUpPage
import "./loginpage.scss";

const LoginPage = ({ close }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loginError, setLoginError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isPasswordLogin, setIsPasswordLogin] = useState(true);
  const [step, setStep] = useState(1);

  const [showSignup, setShowSignup] = useState(false); // State to show sign-up page

  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationLoading, setIsVerificationLoading] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const { setCurrentUser } = useContext(AuthContext);

  // Function to handle phone number submit and check if user exists
  const handlePhoneNumberSubmit = async () => {
    if (!phoneNumber) {
      setPhoneError("手机号码不能为空");
      return;
    }

    setIsLoading(true);

    setTimeout(async () => {
      try {
        const formattedPhoneNumber = `+86${phoneNumber}`;
        const userExists = await checkIfUserExists(formattedPhoneNumber);
        if (userExists) {
          setStep(2);
          setPhoneError("");
          setPhoneNumber("");
          setIsPasswordLogin(false);
        } else {
          // If the user does not exist, show signup page
          showSignupPage(); // Calls the showSignup function to show the sign-up page
        }
      } catch (err) {
        setPhoneError(err.message || "手机号码检查失败");
      } finally {
        setIsLoading(false);
      }
    }, 1700);
  };

  // Function to show the sign-up page
  const showSignupPage = () => {
    setShowSignup(true);
  };

  const handlePhoneVerification = async () => {
    if (!phoneNumber) {
      setPhoneError("手机号码不能为空");
      return;
    }

    setIsVerificationLoading(true);

    try {
      const formattedPhoneNumber = `+86${phoneNumber}`;
      const response = await sendVerificationCode(
        formattedPhoneNumber,
        "login"
      );

      if (response.message === "OK" || response.success) {
        setVerificationSent(true);
        setPhoneError("");

        const countdown = setInterval(() => {
          setTimer((prev) => {
            if (prev === 1) {
              clearInterval(countdown);
              setVerificationSent(false);
              return 60;
            }
            return prev - 1;
          });
        }, 1000);
      } else {
        setPhoneError(response.message || "发送验证码失败");
      }
    } catch (err) {
      setPhoneError(err.message || "发送验证码失败");
    } finally {
      setIsVerificationLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoginLoading(true);

    try {
      let result;
      if (!isPasswordLogin) {
        if (!phoneNumber || !verificationCode) {
          setPhoneError("请输入手机号码和验证码");
          setIsLoginLoading(false);
          return;
        }
        result = await loginUser({
          phoneNumber: `+86${phoneNumber}`,
          verificationCode: verificationCode,
        });
      } else {
        if (!phoneNumber || !password) {
          setLoginError("请输入手机号码和密码");
          setIsLoginLoading(false);
          return;
        }
        result = await loginUser({
          phoneNumber: `+86${phoneNumber}`,
          password: password,
        });
      }

      if (result.success) {
        setCurrentUser(result.username);
        close();
      } else {
        setLoginError(result.message);
      }
    } catch (err) {
      setLoginError(err.message);
    } finally {
      setIsLoginLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <button className="close-button" onClick={close}>
          &times;
        </button>

        {/* Show the Signup Page if showSignup is true */}
        {showSignup ? (
          <SignUpPage close={close} />
        ) : (
          <>
            {/* Step 1: Enter Phone Number */}
            {step === 1 && (
              <>
                <h2>登录/注册</h2>
                <div className="input-container">
                  <label htmlFor="phoneNumber">手机号码</label>
                  <div className="phone-input">
                    <span className="country-code">+86</span>
                    <input
                      type="text"
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="请输入手机号码"
                      required
                    />
                  </div>
                </div>
                {phoneError && <p className="error-message">{phoneError}</p>}
                <button
                  className="login-button"
                  onClick={handlePhoneNumberSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? <div className="loader"></div> : "登录/注册"}
                </button>
              </>
            )}

            {/* Step 2: Password or Verification Code Login */}
            {step === 2 && (
              <>
                <div className="tab-container">
                  <span
                    className={!isPasswordLogin ? "active" : ""}
                    onClick={() => setIsPasswordLogin(false)}
                  >
                    验证码登录
                  </span>
                  <span
                    className={isPasswordLogin ? "active" : ""}
                    onClick={() => setIsPasswordLogin(true)}
                  >
                    密码登录
                  </span>
                </div>
                <form onSubmit={handleLogin}>
                  {isPasswordLogin ? (
                    <>
                      <div className="input-container">
                        <label htmlFor="phoneNumber">手机号码</label>
                        <div className="phone-input">
                          <span className="country-code">+86</span>
                          <input
                            type="text"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="请输入手机号码"
                            required
                          />
                        </div>
                        <label htmlFor="password">密码</label>
                        <input
                          type="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-container phone-verification-container">
                        <label htmlFor="phoneNumber">手机号码</label>
                        <div className="phone-input">
                          <span className="country-code">+86</span>
                          <input
                            type="text"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="请输入手机号码"
                            required
                          />
                        </div>

                        <label htmlFor="verificationCode">验证码</label>
                        <div className="verification-code-input">
                          <input
                            type="text"
                            id="verificationCode"
                            value={verificationCode}
                            onChange={(e) =>
                              setVerificationCode(e.target.value)
                            }
                            placeholder="请输入验证码"
                            required
                          />
                          <button
                            className="verification-button"
                            type="button"
                            onClick={handlePhoneVerification}
                            disabled={isVerificationLoading || verificationSent}
                          >
                            {isVerificationLoading ? (
                              <div className="loader"></div>
                            ) : verificationSent ? (
                              `重新发送验证码 (${timer}s)`
                            ) : (
                              "发送验证码"
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {phoneError && <p className="error-message">{phoneError}</p>}
                  {loginError && <p className="error-message">{loginError}</p>}
                  <button
                    type="submit"
                    className="login-button"
                    disabled={isLoginLoading}
                  >
                    {isLoginLoading ? <div className="loader"></div> : "登录"}
                  </button>
                </form>
              </>
            )}

            {/* Other Login Options at the Bottom */}
            <div className="alternative-login">
              <p>其他登录方式:</p>
              <img
                src={require("./image.png")}
                alt="WeChat Login"
                className="wechat-icon"
              />
              <p className="wechat-login">微信</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
