// App.jsx
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChatProvider } from "./contexts/ChatContext"; // Import ChatProvider
import { AuthProvider } from "./contexts/AuthContext"; // Import AuthProvider
import Discover from "./pages/discover/Discover";
import Recommendation from "./pages/recommendation/Recommendation";
import Follow from "./pages/follow/Follow";
import Community from "./pages/community/Community";
import Messages from "./pages/messages/Messages";
import Notifications from "./pages/notifications/Notifications";
import Profile from "./pages/profile/Profile";
import "./app.css";

function App() {
  return (
    <div className="App">
      <AuthProvider> 
        <ChatProvider>
          <Router>
            <Routes>
              <Route path="/*" element={<Discover />} />
              <Route path="recommendation" element={<Recommendation />} />
              <Route path="follow" element={<Follow />} />
              <Route path="community" element={<Community />} />
              <Route path="messages" element={<Messages />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="profile" element={<Profile />} />
            </Routes>
          </Router>
        </ChatProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
