// ChatInput.jsx
import React, { useContext, useState } from "react";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import "./chat-input.scss";
import { addMessage } from "../../../../db";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ChatContext } from "../../../../contexts/ChatContext";

const ChatInput = ({ selectedUser }) => {
  const [message, setMessage] = useState("");
  const { currentUser } = useContext(AuthContext);
  const { triggerUpdate } = useContext(ChatContext);

  // Function to send a message
  const handleSendMessage = async () => {
    if (message.trim()) {
      if (!currentUser || !selectedUser) {
        alert("Please select a user to chat with.");
        return;
      }

      await addMessage(message, currentUser, selectedUser);
      setMessage(""); 
      triggerUpdate(); 
    }
  };

  // Handle the Enter key press event
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); 
      handleSendMessage(); 
    }
  };

  return (
    <div className="chat-input">
      <div className="input-icons">
        <PhotoSizeSelectActualOutlinedIcon />
        <SentimentSatisfiedOutlinedIcon />
        <AttachFileOutlinedIcon />
      </div>
      <input
        type="text"
        className="input-field"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder={`发消息给 ${selectedUser || "..."}`}
      />
    </div>
  );
};

export default ChatInput;
