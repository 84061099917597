// UserList.jsx
import React, { useState, useEffect } from "react";
import { db } from "../../../../db"; 
import "./chatsidebar.scss";

const UserList = ({ onSelectUser }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const allUsers = await db.users.toArray();
      setUsers(allUsers);
    };

    fetchUsers();
  }, []);

  return (
    <div className="user-list">
      <h3>Start New Conversation</h3>
      {users.map((user) => (
        <div
          key={user.id}
          className="user-item"
          onClick={() => onSelectUser(user.username)}
        >
          <img src={user.avatar} alt={user.username} className="user-avatar" />
          <span>{user.username}</span>
        </div>
      ))}
    </div>
  );
};

export default UserList;
