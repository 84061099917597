import React, { useState, useEffect } from "react";
import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DropDownProfile from "../dropdown/DropDownProfile";
import { db } from "../../db"; // Import the database

import LoginPage from "../../pages/login/LoginPage";
import SignUpPage from "../../pages/signup/Signup";

const Navbar = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const [avatar, setAvatar] = useState(""); // State to hold the user's avatar

  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  const handleLoginClick = () => {
    setShowLogin(true);
    setShowSignup(false);
  };

  const handleSignupClick = () => {
    setShowSignup(true);
    setShowLogin(false);
  };

  const handleClosePopup = () => {
    setShowLogin(false);
    setShowSignup(false);
  };

  // Fetch avatar based on the currently logged-in user
  useEffect(() => {
    const fetchAvatar = async () => {
      const username = sessionStorage.getItem("username"); // Get logged-in username from sessionStorage
      if (username) {
        try {
          const user = await db.users.where({ username }).first(); // Find user by username
          if (user && user.avatar) {
            setAvatar(user.avatar); // Set the user's avatar from the database
          } else {
            setAvatar("./image.png"); // Fallback to default avatar if none is found
          }
        } catch (err) {
          console.error("Failed to fetch user avatar:", err);
        }
      } else {
        setAvatar("./image.png"); // Set default avatar if no user is logged in
      }
    };

    fetchAvatar();
  }, []);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <SearchOutlinedIcon />
          <input type="text" placeholder="在DEIN中搜索" />
        </div>
        {/* <div className="button-group">
          <button className="home-button" onClick={handleLoginClick}>
            Login
          </button>
          <button className="home-button signup" onClick={handleSignupClick}>
            Signup
          </button>
        </div> */}

        {/* Conditionally render the login or signup box */}
        {showLogin && (
          <div className="login-overlay">
            <div className="login-popup">
              <LoginPage
                close={handleClosePopup}
                showSignup={handleSignupClick}
              />
            </div>
          </div>
        )}

        {showSignup && (
          <div className="signup-overlay">
            <div className="signup-popup">
              <SignUpPage
                close={handleClosePopup}
                showLogin={handleLoginClick}
              />
            </div>
          </div>
        )}

        <div className="items">
          <div className="item">
            <span>音乐人中心</span>
          </div>
          <div className="item">
            <img
              src={avatar} // Use dynamic avatar from state
              alt="User Avatar"
              className="avatar"
              onClick={() => setOpenProfile((prev) => !prev)}
            />
          </div>
          {openProfile && <DropDownProfile setOpenProfile={setOpenProfile} />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
