import React, { useState, useContext } from "react";
import { signupUser, sendVerificationCode } from "../../db"; // Import signupUser and sendVerificationCode from db.js
import { AuthContext } from "../../contexts/AuthContext"; // Import AuthContext for setting current user
import "./signup.scss";

const SignUpPage = ({ close, showLogin }) => {
  const { setCurrentUser } = useContext(AuthContext); // Get setCurrentUser from AuthContext

  const [phoneNumber, setPhoneNumber] = useState(""); // State for phone number
  const [verificationCode, setVerificationCode] = useState(""); // State for verification code
  const [error, setError] = useState(""); // State for error messages
  const [verificationSent, setVerificationSent] = useState(false); // State to track if verification was sent
  const [timer, setTimer] = useState(60); // Timer for resending verification code
  const [isSuccess, setIsSuccess] = useState(false); // State to trigger success animation
  const [isSignUpLoading, setIsSignUpLoading] = useState(false); // Loading state for signup button
  const [isVerificationLoading, setIsVerificationLoading] = useState(false); // Loading state for verification button

  // Handle sending the verification code for registration
  const handlePhoneVerification = async () => {
    if (!phoneNumber) {
      setError("手机号码不能为空");
      return;
    }

    setIsVerificationLoading(true); // Start loading animation

    try {
      const formattedPhoneNumber = `+86${phoneNumber}`;
      const response = await sendVerificationCode(
        formattedPhoneNumber,
        "register"
      );

      if (response.message === "OK" || response.success) {
        setVerificationSent(true);
        setError("");

        const countdown = setInterval(() => {
          setTimer((prev) => {
            if (prev === 1) {
              clearInterval(countdown);
              setVerificationSent(false);
              return 60;
            }
            return prev - 1;
          });
        }, 1000);
      } else {
        setError(response.message || "发送验证码失败");
      }
    } catch (err) {
      setError(err.message || "发送验证码失败");
    } finally {
      setIsVerificationLoading(false); // Stop loading animation
    }
  };

  // Handle sign-up logic
  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!phoneNumber || !verificationCode) {
      setError("请输入手机号码和验证码");
      return;
    }

    setIsSignUpLoading(true); // Start loading animation for signup button

    try {
      let result = await signupUser(`+86${phoneNumber}`, verificationCode);

      if (result.success) {
        setError("");
        setCurrentUser(result.username);
        setIsSuccess(true); // Trigger success animation
        setTimeout(() => {
          close(); // Close the window after 2 seconds
        }, 2000); // Set a 2-second delay for the success animation
      } else {
        setError(result.message || "注册失败");
      }
    } catch (err) {
      setError(err.message || "注册失败");
    } finally {
      setIsSignUpLoading(false); // Stop loading animation
    }
  };

  return (
    <div className="signup-page">
      <div className="signup-container">
        <button className="close-button" onClick={close}>
          &times;
        </button>
        {!isSuccess ? (
          <>
            <h2>注册</h2>
            <form onSubmit={handleSignUp}>
              <div className="input-container phone-verification-container">
                <label htmlFor="phoneNumber">手机号码</label>
                <div className="phone-input">
                  <span className="country-code">+86</span>
                  <input
                    type="text"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="请输入手机号码"
                    required
                  />
                </div>
                <div className="verification-code-input">
                  <input
                    type="text"
                    id="verificationCode"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    placeholder="请输入验证码"
                    required
                  />
                  <button
                    className="verification-button"
                    type="button"
                    onClick={handlePhoneVerification}
                    disabled={isVerificationLoading || verificationSent}
                  >
                    {isVerificationLoading ? (
                      <div className="loader"></div> // Show loader animation
                    ) : verificationSent ? (
                      `重新发送验证码 (${timer}s)`
                    ) : (
                      "发送验证码"
                    )}
                  </button>
                </div>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <button
                type="submit"
                className="signup-button"
                disabled={isSignUpLoading}
              >
                {isSignUpLoading ? (
                  <div className="loader"></div> // Show loader animation for sign-up button
                ) : (
                  "注册"
                )}
              </button>
            </form>
            <div className="alternative-login">
              <p>其他登录方式:</p>
              <img
                src={require("./image.png")}
                alt="WeChat Login"
                className="wechat-icon"
              />
              <p className="wechat-login">微信</p>
            </div>
          </>
        ) : (
          <div className="success-animation">
            <h2>注册成功!</h2>
            <div className="success-icon">✔️</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpPage;
