import "./messages.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import ChatSidebar from "./chatroom/chat-sidebar/ChatSidebar";

import Chat from "./chatroom/chats/Chat";
import { ChatProvider } from  "../../contexts/ChatContext";
;

const Messages = () => {
  return (
    <div className="messages">
      <Sidebar />
      <div className="messages-container">
        <Navbar />
        <ChatProvider>
          <div className="chatroom-container">
            <ChatSidebar /> {/* Sidebar will access context */}
            <Chat /> {/* Chat component will access context */}
          </div>
        </ChatProvider>
      </div>
    </div>
  );
};

export default Messages;
