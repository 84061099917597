import React, { useState, useEffect, useMemo } from "react";
import "./chatsidebar.scss";
import { db } from "../../../../db";

const MAX_MESSAGE_LENGTH = 25;

// Helper function to truncate messages
const truncateMessage = (message, maxLength) => {
  if (message.length > maxLength) {
    return message.substring(0, maxLength) + "......";
  }
  return message;
};

// Helper function to format timestamp
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffMinutes = Math.floor((now - date) / 60000);
  if (diffMinutes < 60) {
    return `${diffMinutes}分钟前`;
  }
  return date.toLocaleDateString("zh-CN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

const Chats = ({ currentUser, recentMessages, selectedUser, onSelectUser }) => {
  const [avatars, setAvatars] = useState({});

  const displayMessages = useMemo(() => {
    const grouped = recentMessages.reduce((acc, message) => {
      const participants = [message.author, message.recipient].sort();
      const key = `${participants[0]}-${participants[1]}`;
      acc[key] = message;
      return acc;
    }, {});

    // Sort messages by timestamp in descending order (most recent first)
    return Object.values(grouped).sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );
  }, [recentMessages]);

  useEffect(() => {
    const fetchAvatars = async () => {
      const avatarsMap = {};
      for (const message of displayMessages) {
        // Determine the other user (the one who is not the current user)
        const otherUser =
          message.author === currentUser ? message.recipient : message.author;

        if (!avatars[otherUser]) {
          // Check if avatar is not already fetched
          const user = await db.users.where({ username: otherUser }).first();

          if (user && user.avatar) {
            avatarsMap[otherUser] = user.avatar;
          }
        }
      }
      setAvatars((prev) => ({ ...prev, ...avatarsMap })); // Merge new avatars with existing ones
    };

    fetchAvatars();
  }, [displayMessages, currentUser]);

  return (
    <div className="chats">
      {displayMessages.map((message, index) => {
        const otherUser =
          message.author === currentUser ? message.recipient : message.author;
        return (
          <div
            key={`${otherUser}-${index}`} // Use unique key
            className={`chat-user ${
              selectedUser === otherUser ? "selected" : ""
            }`}
            onClick={() => onSelectUser(otherUser)} // Update selected user when clicked
          >
            <div className="user-info">
              {avatars[otherUser] && (
                <img src={avatars[otherUser]} alt={otherUser} />
              )}
              <div className="chat-user-name">
                <span>{otherUser}</span>
                <p>{truncateMessage(message.message, MAX_MESSAGE_LENGTH)}</p>
              </div>
            </div>
            <span className="message-time">
              {formatTimestamp(message.timestamp)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Chats;
