// ChatNavbar.jsx
import ChatSearch from "./ChatSearch";
import "./chat-navbar.scss";

const ChatNavbar = ({ onSelectUser }) => {
  return (
    <div className="chat-navbar">
      <ChatSearch onSelectUser={onSelectUser} />
    </div>
  );
};

export default ChatNavbar;
