import "./democard.scss";
import React, { useState } from "react";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const DemoCard = () => {
  // State to track whether the heart is clicked
  const [isLiked, setIsLiked] = useState(false);

  // State for the like counter
  const [likeCount, setLikeCount] = useState(122);

  // State to manage play/pause
  const [isPlaying, setIsPlaying] = useState(false);

  // Handle click on heart icon
  const handleLikeClick = () => {
    if (!isLiked) {
      setLikeCount(likeCount + 1); // Increment like count when liked
    } else {
      setLikeCount(likeCount - 1); // Decrement like count when unliked
    }
    setIsLiked(!isLiked); // Toggle like state
  };

  // Handle play/pause button
  const handlePlayPauseClick = () => {
    setIsPlaying(!isPlaying); // Toggle play/pause state
  };

  return (
    <div className="demo-card">
      <div className="demo-card-inner">
        <div className="image-container">
          <img
            src="https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w1200/2023/10/free-images.jpg"
            alt="free-image"
          />
          <div className="hover-overlay">
            <div className="play-button" onClick={handlePlayPauseClick}>
              {isPlaying ? <PauseIcon style={{ fontSize: 50 }} /> : <PlayArrowIcon style={{ fontSize: 50 }} />}
            </div>
            <div className={`waveform ${isPlaying ? "active" : ""}`}>
              <div className="waveform-bar"></div>
              <div className="waveform-bar"></div>
              <div className="waveform-bar"></div>
              <div className="waveform-bar"></div>
              <div className="waveform-bar"></div>
            </div>
          </div>
        </div>

        <div className="bottom-left">
          <span className="duration">01:44</span>
        </div>

        <div className="like-section" onClick={handleLikeClick}>
          {isLiked ? (
            <FavoriteIcon className="heart-icon liked" />
          ) : (
            <FavoriteBorderOutlinedIcon className="heart-icon" />
          )}
          <span className="like-counter">{likeCount}</span>
        </div>
      </div>
      
      {/* Text section below the card */}
      <div className="demo-info">
        <p className="demo-name">DEMO NAME</p>
        <p className="demo-date">2021.01.01</p>
      </div>
    </div>
  );
};


export default DemoCard;
